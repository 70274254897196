import { DynamicForm } from "@blings/dynamic-form";
import BlingsPlatformTheme from "../../config/antdTheme";
import { cleanEmptyStringInValue } from "../../helpers/object.helpers";
import { useMst } from "../../stores/Root";
import "./BlingsDynamicForm.scss";

interface Props {
  readOnly?: boolean;
  editable?: boolean;
  dynamicFormProps: {
    schema: any;
    currentFormData: any;
    setLastFormChange: any;
    setCurrentFormData: any;
    setFormHasChanged: any;
    setChangedFromRecordData: any;
    submit: any;
  };
  className?: string;
}

export function BlingsDynamicForm({
  readOnly,
  editable,
  dynamicFormProps,
  className,
}: Props) {
  const { getAssetsUploadStatus, uploadAssetToProject } = useMst((state) => {
    return {
      uploadAssetToProject: state.liveControlStore.uploadAssetToProject,
    };
  });

  const {
    schema,
    currentFormData,
    setLastFormChange,
    setCurrentFormData,
    setChangedFromRecordData,
    submit,
  } = dynamicFormProps;

  return (
    <div className={"schema-view " + className}>
      <DynamicForm
        // @ts-ignore
        themeConfig={BlingsPlatformTheme}
        schema={schema}
        formData={currentFormData}
        editable={editable || false}
        readable={readOnly || false}
        onChange={(formData: any) => {
          setLastFormChange(cleanEmptyStringInValue(formData));
        }}
        saveData={(d) => {
          setCurrentFormData(cleanEmptyStringInValue(d));
          setChangedFromRecordData(true);
        }}
        submit={submit}
        uploadAssetToProject={uploadAssetToProject as any} // TODO: FIX THIS
        getAssetsUploadStatus={getAssetsUploadStatus}
      />
    </div>
  );
}

import { Instance, getRoot, getSnapshot, types } from "mobx-state-tree";
import {
  asyncOpStateType,
  INITIAL_ASYNC_OP_STATE,
} from "../../types/mst/async-op-state";
import { createDataPointActions } from "./createDataPointActions";
import { saveSchemasActions } from "./saveSchemasActions";
import { saveFileNameActions } from "./saveFileNameActions";
import { saveMiniSiteConfigActions } from "./saveMiniSiteConfigActions";
import _ from "lodash";
import {
  ProjectProps,
  DataFileProps,
  VidPartProps,
  ModProps,
} from "../platformSchema";
import {
  ModInput,
  PlayerSettingsInput,
  UpdateProjectInput,
  ExperimentInput,
  ExperimentOptimizationTechnique,
  VidPartInput,
} from "../../API";
import { emptySchema } from "../../helpers/jsonSchema.helpers";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { flattenObject } from "../../helpers/object.helpers";
import { generateCSV } from "../../helpers/file.helpers";
import { downloadFile } from "../../helpers/file.helpers";
import { getAssetLayerPairs } from "./getAssetLayerPairs";
import {
  Additional,
  EditVideoExposedToPlatformType,
  EditVideoInteractiveType,
  EditVideoTextType,
  EditVideoTypes,
  ExposedToPlatformModInput,
  InteractiveModInput,
  TextLayerInput,
} from "../../types/EditVideoTypes";
import { updateAsyncStatus } from "../async-op-states";
import {
  changeProjectExperimentOptimizationTechnique,
  getLatestProjectVersion,
  publishProject,
  savePublished,
  updateVersion,
} from "../../utils/projectTools";
import { IRootModel, rootStore } from "../Root";
import { DynamicThumbnailsModel } from "./DynamicThumbnailModel";
import { ExperimentModel } from "../ExperimentStore";
import { IFlowDiagram } from "@blings/blings-player";
export const mainAssetKey = "main_asset_keys";
export const sequenceLayerId = "sequence_";
export const seqImgId = "imgSeq_";
export const PLATFORM_TEXT_CONTROL = "PLATFORM_TEXT_CONTROL";
export const PLATFORM_INTERACTIVE_CONTROL = "PLATFORM_INTERACTIVE_CONTROL";
export const PLATFORM_EXPOSED_CONTROL = "PLATFORM_EXPOSED_CONTROL";
export const DataFileModel = types.model("DataFile", {
  ...DataFileProps,
  // fileName: types.string,
  // tag: types.maybeNull(types.string),
  // createdAt: types.maybeNull(types.string),
});
const FlowDiagramEdgeModel = types.model("FlowDiagramEdge", {
  source: types.string,
  target: types.string,
  sourceHandle: types.maybeNull(types.boolean),
});
const FlowDiagramNodeModel = types.model("FlowDiagramNode", {
  id: types.string,
  label: types.string,
  type: types.enumeration("FlowDiagramNodeType", [
    "scene",
    "condition",
    "lineup",
  ]),
  data: types.maybeNull(types.string),
  lineupId: types.maybeNull(types.string),
});
export const FlowDiagramModel = types
  .model("FlowDiagram", {
    edges: types.array(FlowDiagramEdgeModel),
    nodes: types.array(FlowDiagramNodeModel),
  })
  .views((self) => ({
    get lineups() {
      return self.nodes.reduce<Array<string>>((acc, node) => {
        if (node.lineupId) {
          acc.push(node.lineupId);
        }
        return acc;
      }, []);
    },
  }));
export const ModModel = types.model("Mod", {
  ...ModProps,
  origin: types.optional(types.maybeNull(types.string), null),
});
/*
export const PlayerSettingsModel = types.Date.model("PlayerSettings", {
  jsonData: types.maybeNull(types.frozen())
})
*/
export const VidPartModel = types
  .model("VidPart", {
    ...VidPartProps,
    jsonData: types.maybeNull(types.frozen()),
    assetLayerPairs: types.maybeNull(
      types.map(
        types.array(
          types.model({
            nm: types.maybeNull(types.string),
            uid: types.maybe(types.number),
          })
        )
      )
    ),
    modsArr: types.maybeNull(types.array(types.maybeNull(ModModel))),
    initialModsArr: types.frozen(), // Saved in db
    mods: types.maybeNull(types.string), // Current state
    playerVersionToUse: types.maybeNull(types.string),
    origin: types.maybeNull(types.string),
    // fileName: types.string,
    // tag: types.maybeNull(types.string),
    // createdAt: types.maybeNull(types.string),
  })
  .actions((self) => ({
    /**
     * This method will delete the specific connector from the modsArray based on layerName and type
     * @param layerName
     * @param type
     */
    updateTextMod(
      layerName: string,
      input: TextLayerInput,
      asset: string | undefined,
      additionals: Additional[] | undefined,
      layerUid?: number
    ) {
      const { newValue, newFontValue, experimentId } = input;
      const newMods = self?.modsArr?.map((mod) => {
        const dataStr = mod?.dataStr && JSON.parse(mod.dataStr);
        if (
          ((dataStr?.layerUid && layerUid && dataStr.layerUid === layerUid) ||
            dataStr.layerName === layerName) &&
          dataStr.type === "text" &&
          !dataStr?.isDisabled
        ) {
          const newDatastr = JSON.stringify({
            ...dataStr,
            value: newValue || dataStr?.value,
            fontSize: newFontValue || dataStr?.fontValue,
            assetId: asset,
            additionals,
            experimentId: experimentId || dataStr?.experimentId,
          });
          return {
            ...mod,
            dataStr: newDatastr,
            origin: PLATFORM_TEXT_CONTROL,
            id: mod?.id ?? 0,
            name: mod?.name || "",
          };
        }
        return mod;
      });
      self.modsArr?.replace(newMods || []);
    },
    /**
     * Create a value connector on the layer
     * @param layerName
     * @param value
     * @param asset
     */
    createTextMod(
      layerName: string,
      input: TextLayerInput,
      asset: string | undefined,
      additionals: Additional[] | undefined,
      layerUid?: number
    ) {
      const { newValue, experimentId, newFontValue } = input;
      // Generate a new Id
      let id = 0;
      if (self.modsArr) {
        id =
          self.modsArr.reduce(
            (maxId, mod) => Math.max(mod?.id || 0, maxId),
            -1
          ) + 1 || 0;
      }
      const newMod = {
        dataStr: JSON.stringify({
          layerName,
          layerUid,
          type: "text",
          fontSize: newFontValue,
          value: newValue,
          assetId: asset,
          experimentId,
          additionals,
        }),
        id,
        origin: PLATFORM_TEXT_CONTROL,
        name: "",
      };
      const mods = [...(self.modsArr || []), newMod];
      self.modsArr?.replace(mods);
    },
  }))
  .actions((self) => ({
    updateJsonData(jsonData: any) {
      self.jsonData = jsonData;
      const [assetLayerPairs, mainPairs] = getAssetLayerPairs(jsonData);
      assetLayerPairs[mainAssetKey] = mainPairs;
      Object.keys(assetLayerPairs).forEach((key) => {
        if (key.indexOf(sequenceLayerId) === 0) {
          delete assetLayerPairs[key];
        }
      });
      self.assetLayerPairs = assetLayerPairs;
    },
    // Reset the initial Mods array to the latest saved version
    setInitialMods(modsArr: any) {
      self.initialModsArr = modsArr;
    },
    /**
     *  - Update the mod to contain the new updates
     */
    updateInteractiveMod(
      interactiveMod: EditVideoInteractiveType,
      input: InteractiveModInput
    ) {
      const newMods = self.modsArr?.map((mod) => {
        if (interactiveMod.id === mod?.id.toString()) {
          const dataStr = mod?.dataStr && JSON.parse(mod.dataStr);
          const newDatastr = JSON.stringify({
            ...dataStr,
            ctaName:
              input.newCtaName != null ? input.newCtaName : dataStr.ctaName,
            value: input.newValue != null ? input.newValue : dataStr.value,
            experimentId: input.experimentId || dataStr?.experimentId,
          });
          return {
            ...mod,
            dataStr: newDatastr,
            origin: PLATFORM_INTERACTIVE_CONTROL,
            name: input.newCtaName != null ? input.newCtaName : mod.name,
          };
        }
        return mod;
      });
      self.modsArr?.replace(newMods || []);
    },
    /**
     *  - Update the mod to contain the new updates
     */
    updateExposedToPlatformMod(
      exposedToPlatformMod: EditVideoExposedToPlatformType,
      input: ExposedToPlatformModInput
    ) {
      const newMods = self.modsArr?.map((mod) => {
        if (exposedToPlatformMod.id === mod?.id.toString()) {
          const dataStr = mod?.dataStr && JSON.parse(mod.dataStr);
          const newDatastr = JSON.stringify({
            ...dataStr,
            value:
              input.newValue != null ? input.newValue : dataStr.value || "",
            experimentId:
              input.experimentId != null
                ? input.experimentId
                : dataStr?.experimentId,
          });
          return {
            ...mod,
            dataStr: newDatastr,
            origin: PLATFORM_EXPOSED_CONTROL,
          };
        }
        return mod;
      });
      self.modsArr?.replace(newMods || []);
    },
    /**
     *  - Add Mod to ModsArr (value and origin)
     */
    updateTextMods(textLayer: EditVideoTextType, input: TextLayerInput) {
      const textMod = self.modsArr?.find((mod) => {
        const dataStr = mod?.dataStr && JSON.parse(mod.dataStr);
        return (
          (dataStr?.layerUid && textLayer.layerData?.uid
            ? dataStr.layerUid === textLayer.layerData.uid
            : dataStr?.layerName === textLayer.layerData.nm) &&
          dataStr.type === "text" &&
          !dataStr?.isDisabled
        );
      });

      if (textMod) {
        if (input.newValue == null) {
          //Keep value if variant change
          const dataStr = textMod?.dataStr && JSON.parse(textMod.dataStr);
          input.newValue = dataStr?.value;
        }

        return self.updateTextMod(
          textLayer.layerData.nm,
          input,
          textLayer?.asset,
          textLayer.additionals,
          textLayer.layerData?.uid
        );
      }
      return self.createTextMod(
        textLayer.layerData.nm,
        input,
        textLayer?.asset,
        textLayer.additionals,
        textLayer.layerData?.uid
      );
    },
  }));

export const MinisiteConfigModel = types.model("MinisiteConfig", {
  scenes: types.maybeNull(types.array(types.string)),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  thumbUrl: types.maybeNull(types.string),
  faviconUrl: types.maybeNull(types.string),
  preCreate: types.maybeNull(types.string),
});

export const PlayerSettingsModel = types.model("PlayerSettingsStore", {
  posterFrame: types.maybeNull(types.number),
  showTimeline: types.maybeNull(types.boolean),
  storiesMode: types.maybeNull(types.boolean),
  color_loader: types.maybeNull(types.string),
  color_ctrlBtns: types.maybeNull(types.string),
  color_rail: types.maybeNull(types.string),
  color_progress: types.maybeNull(types.string),
  color_thumb: types.maybeNull(types.string),
  color_bg: types.maybeNull(types.string),
  muted: types.maybeNull(types.boolean),
  autoplay: types.maybeNull(types.boolean),
  autoplay_delay: types.maybeNull(types.number),
  autoReplay: types.maybeNull(types.boolean),
  blingsLogoColor: types.maybeNull(types.string),
  showBlingsLogo: types.maybeNull(types.number),
  loadingImage: types.maybeNull(types.string),
  loadingText: types.maybeNull(types.string),
});

export type IDataFileModel = Instance<typeof DataFileModel>;

const DataPointModel = types.model({
  dataId: types.string,
  sourceId: types.string,
  record: types.frozen(),
});
export type IDataPointModel = Instance<typeof DataPointModel>;

export const ProjectBaseModel = types
  .model("Project", {
    ...ProjectProps,
    account: types.maybeNull(
      types.model({
        id: types.string,
        aliasId: types.string,
        minisiteDomain: types.optional(
          types.union(types.string, types.null, types.undefined),
          ""
        ),
      })
    ),
    workSpaceUpdatedAt: types.optional(types.string, ""),
    updatedAt: types.maybeNull(types.string),
    dataFiles: types.maybeNull(types.array(DataFileModel)),
    workspaceVideoParts: types.optional(types.array(VidPartModel), []),
    publishedVideoParts: types.optional(types.frozen(), []),
    experiments: types.optional(types.frozen(), []),
    newDPFormData: types.optional(types.frozen(), {}), // immutable !
    saveLiveControlStatus: asyncOpStateType,
    savePerVideoDataStatus: asyncOpStateType,
    saveMiniSiteConfigStatus: asyncOpStateType,
    settings: types.maybeNull(types.frozen()), // immutable !
    saveModsStatus: asyncOpStateType,
    refreshModsStatus: asyncOpStateType, // when mod is changed in edit video
    createNewDPStatus: asyncOpStateType, // when mod is saved in edit video
    createdDPList: types.optional(types.array(DataPointModel), []),
    isPublishing: types.optional(types.boolean, false),
    publishedAt: types.maybeNull(types.string),
    customHtml: types.maybeNull(types.string),
    // projectVersions: types.maybeNull(undefined), TODO
    playerSettings: types.maybeNull(PlayerSettingsModel), //,  -- check
    minisiteConfig: types.optional(MinisiteConfigModel, {}),
    playerVersionToUse: types.maybeNull(types.string),
    hasFormData: types.boolean,
    dynamicThumbnail: DynamicThumbnailsModel,
    draftExperiments: types.maybeNull(types.array(ExperimentModel)),
    experimentOptimizationTechnique: types.maybeNull(
      types.frozen(ExperimentOptimizationTechnique)
    ),
    flowDiagram: types.maybeNull(FlowDiagramModel),
    flowDiagramDraft: types.maybeNull(FlowDiagramModel),
  })
  .views((self) => ({
    get videoPartNames() {
      const videoPartNames =
        self.publishedVideoParts?.map((vdp: any) => vdp?.name || "") || [];
      return videoPartNames as string[];
    },
    get projectAccountDomain() {
      return self.account?.minisiteDomain || "mp5.live";
    },
    get hasUnsavedChanges() {
      // compare every scene's initialModsArr to the current modsArr
      const savedMods = self.workspaceVideoParts.map((vp) => ({
        name: vp.name,
        modsArr: vp.initialModsArr?.map((mod: any) => ({
          id: mod.id,
          dataStr: mod.dataStr,
          name: mod.name,
          origin: mod.origin,
          // disabled: mod.disabled,
        })),
      }));
      const currentMods = self.publishedVideoParts.map((vp: any) => ({
        name: vp.name,
        modsArr: vp.modsArr?.map((mod: any) => ({
          id: mod.id,
          dataStr: mod.dataStr,
          name: mod.name,
          origin: mod.origin,
          // disabled: mod.disabled,
        })),
      }));
      const {
        experimentStore: {
          unchangedDraftExperimentsCleaned,
          unchangedExperimentsCleaned,
        },
      } = getRoot<any>(self);
      return (
        !_.isEqual(currentMods, savedMods) ||
        !_.isEqual(
          unchangedDraftExperimentsCleaned,
          unchangedExperimentsCleaned
        )
      );
    },
    get isControlDeprecated() {
      const keys = Object.keys(self?.settings || {});
      return keys.length === 0;
    },
    get settingsSchema() {
      if (self.settingsJsonSchemaStr)
        return JSON.parse(self.settingsJsonSchemaStr);
      return JSON.parse(JSON.stringify(emptySchema));
    },
    get stateJsonSchema() {
      if (self.stateJsonSchemaStr) return JSON.parse(self.stateJsonSchemaStr);
      return JSON.parse(JSON.stringify(emptySchema));
    },
    // returns true if the draft is up to date with the published version
    get isLiveVersion() {
      const lastPublishedAt = self.publishedAt || self.updatedAt;
      return !!(
        lastPublishedAt &&
        ((self?.workSpaceUpdatedAt &&
          self.workSpaceUpdatedAt < lastPublishedAt) ||
          lastPublishedAt === self?.createdAt)
      );
    },
    get flowDiagramSnapshot(): IFlowDiagram | undefined {
      return self.flowDiagramDraft
        ? (getSnapshot(self.flowDiagramDraft) as IFlowDiagram)
        : undefined;
    },
  }))
  .actions((self) => ({
    updateSaveModsStatus: updateAsyncStatus(self, "saveModsStatus"),
    updateRefreshModsStatus: updateAsyncStatus(self, "refreshModsStatus"),
    setIsPublishing(isPublishing: boolean) {
      self.isPublishing = isPublishing;
    },
    setCreatedDPList(list: IDataPointModel[]) {
      self.createdDPList.replace(list);
    },
    setPublishedVideoParts(videoParts: any) {
      self.publishedVideoParts = videoParts;
    },
    setPublishedExperiments(experiments: any) {
      self.experiments = experiments;
    },
  }))
  .actions((self) => ({
    updatePlayerSettings(newSettings: any) {
      self.playerSettings = newSettings;
    },
    /**
     * Update the scenes with the latest changes and save in draft. identifiable by type or id
     */
    async saveMod(sceneName: string, layerOrMod: EditVideoTypes) {
      self.updateSaveModsStatus(AsyncOpState.Saving);
      getRoot<IRootModel>(
        self
      ).experimentStore.changedToUnchangedSpecificDraftExperiment(
        layerOrMod?.experimentId
      );
      const scene = self.workspaceVideoParts.find(
        (vp) => vp.name === sceneName
      );
      const initialModsArr = scene?.initialModsArr
        ? [...scene.initialModsArr]
        : [];
      const modsArr = scene?.modsArr || [];
      const indexOfConnectorInitial = initialModsArr.findIndex((mod: any) => {
        if ("id" in layerOrMod) {
          //if mod
          return layerOrMod.id === mod.id.toString();
        } else {
          // Text layer
          const dataStr = mod?.dataStr && JSON.parse(mod.dataStr);
          if (dataStr?.layerUid && layerOrMod.layerData?.uid) {
            return (
              dataStr.layerUid === layerOrMod.layerData.uid &&
              dataStr.type === "text" &&
              !dataStr?.isDisabled
            );
          } else {
            return (
              dataStr.layerName === layerOrMod.layerData.nm &&
              dataStr.type === "text" &&
              !dataStr?.isDisabled
            );
          }
        }
      });
      const indexOfConnectorCurrent = modsArr.findIndex((mod: any) => {
        if ("id" in layerOrMod) {
          return layerOrMod.id === mod.id.toString();
        } else {
          const dataStr = mod?.dataStr && JSON.parse(mod.dataStr);
          if (dataStr?.layerUid && layerOrMod.layerData?.uid) {
            return (
              dataStr.layerUid === layerOrMod.layerData.uid &&
              dataStr.type === "text" &&
              !dataStr?.isDisabled
            );
          } else {
            return (
              dataStr.layerName === layerOrMod.layerData.nm &&
              dataStr.type === "text" &&
              !dataStr?.isDisabled
            );
          }
        }
      });
      // If a connector has been changed
      if (indexOfConnectorInitial > -1 && indexOfConnectorCurrent > -1) {
        initialModsArr[indexOfConnectorInitial] =
          modsArr?.[indexOfConnectorCurrent];
      }
      // If a connector has been deleted
      else if (indexOfConnectorInitial > -1 && indexOfConnectorCurrent === -1) {
        initialModsArr.splice(indexOfConnectorInitial, 1);
      }
      //If a connector has been added
      else if (indexOfConnectorInitial === -1 && indexOfConnectorCurrent > -1) {
        initialModsArr.push(modsArr?.[indexOfConnectorCurrent]);
      }
      try {
        const updatedScenes: VidPartInput[] = self.workspaceVideoParts?.map(
          (vp) => ({
            name: vp.name,
            jsonUrl: vp.jsonUrl,
            modsArr: sceneName === vp.name ? initialModsArr : vp.initialModsArr,
            mods: vp.mods,
            updatedAt: vp.updatedAt,
            playerVersionToUse: vp.playerVersionToUse,
            origin: vp.origin,
          })
        );
        await updateVersion({
          id: self.id,
          videoParts: updatedScenes,
          experiments: getRoot<IRootModel>(self).experimentStore
            .unchangedDraftExperiments as ExperimentInput[],
        });
        scene?.setInitialMods(initialModsArr);
        self.updateSaveModsStatus(AsyncOpState.Success);
      } catch (e) {
        self.updateSaveModsStatus(AsyncOpState.Error);
        console.error("err", e);
      }
    },
    changeSavePlayerSettingsStatus(newStatus: AsyncOpState) {
      self.saveLiveControlStatus = newStatus;
    },
    findModByExperimentId(experimentId: string) {
      let mod = undefined;

      self.publishedVideoParts.forEach((vp: any) => {
        // console.log("vp", vp)
        if (vp.modsArr) {
          vp.modsArr.forEach((m: any) => {
            const modData = JSON.parse(m?.dataStr || "{}");
            // console.log("modData", modData.experimentId, experimentId)
            if (modData.experimentId === experimentId) {
              mod = m;
            }
          });
        }
      });
      return mod;
    },
    async savePlayerSettings(
      playerSettings: PlayerSettingsInput,
      thumbnail: string
    ) {
      const beforePlayerSettings = self.playerSettings;
      const stringifiedPlayerSettings = JSON.stringify(self.playerSettings);
      this.updatePlayerSettings({ ...beforePlayerSettings, playerSettings });
      this.changeSavePlayerSettingsStatus(AsyncOpState.Saving);
      try {
        const cpi = { id: self.id, playerSettings, thumbS3Url: thumbnail };
        await savePublished(cpi);
      } catch (e) {
        console.error("err", e);
        this.changeSavePlayerSettingsStatus(AsyncOpState.Error);
        setTimeout(() => {
          this.changeSavePlayerSettingsStatus(INITIAL_ASYNC_OP_STATE);
          this.updatePlayerSettings(JSON.parse(stringifiedPlayerSettings));
        }, 3000);
      }
    },
    async uploadAndSetThumbnail(thumbnail: string) {
      try {
        const cpi = { id: self.id, thumbS3Url: thumbnail };
        await savePublished(cpi);
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * Get the published version, then update it with the new videopart changes and upload it.
     * Finally, load projects
     */
    async publishProject() {
      self.setIsPublishing(true);
      const latestProjectVersion = await getLatestProjectVersion(self.id);
      const updatedVideoparts =
        latestProjectVersion.videoParts?.map((vp) => {
          const { __typename, ...rest } = vp;
          const workSpaceVideopart = self.workspaceVideoParts.find(
            (wvp) => wvp.name === vp.name
          );
          const modsArray: ModInput[] | undefined =
            workSpaceVideopart?.modsArr?.map((mod) => ({
              dataStr: mod?.dataStr || "",
              id: mod?.id || 0,
              origin: mod?.origin || null,
              name: mod?.name,
            }));
          return { ...rest, modsArr: modsArray || vp.modsArr }; //Update the mods Array
        }) || [];
      (latestProjectVersion as UpdateProjectInput).videoParts =
        updatedVideoparts;
      const variantStore = getRoot<IRootModel>(self).experimentStore;
      const updatedExperiments =
        variantStore.unchangedDraftExperiments as ExperimentInput[];
      if (updatedExperiments.length > 0) {
        (latestProjectVersion as UpdateProjectInput).experiments =
          updatedExperiments;
      }
      latestProjectVersion.experimentOptimizationTechnique =
        variantStore.selectedExperimentOptimizationTechnique ||
        ExperimentOptimizationTechnique.DISABLED;
      if (latestProjectVersion) await publishProject(latestProjectVersion);
      self.setIsPublishing(false);
      self.setPublishedVideoParts(updatedVideoparts);
      self.setPublishedExperiments(
        latestProjectVersion?.experiments?.map((exp) => ({
          id: exp.id,
          options: exp.variants || [],
          type: exp.type,
        }))
      );
      if (self.id) await rootStore.projectsStore.loadProject(self.id);
    },
    async changeExperimentOptimizationType() {
      const currentOptimizationTechnique =
        getRoot<IRootModel>(self).experimentStore
          .selectedExperimentOptimizationTechnique ||
        ExperimentOptimizationTechnique.DISABLED;
      const currentProjectId = self.id;
      changeProjectExperimentOptimizationTechnique(
        currentProjectId,
        currentOptimizationTechnique
      );
    },
    setDynamicThumbnailId(id: string) {
      // Push the new thumbnail as a snapshot
      self.dynamicThumbnail.id = id;
    },

    downloadExampleCSV() {
      const perVideoExample = self.stateJsonSchema.examples[0] || null;
      if (!perVideoExample) throw new Error("Example data not set");
      const flattenData = flattenObject(perVideoExample);
      const keys = ["id", ...Object.keys(flattenData)];
      const values = ["example_id", ...Object.values(flattenData)] as (
        | string
        | number
      )[];
      const csv = generateCSV(keys, [values]);
      downloadFile(csv, `${self.title}.csv`, "text/csv");
    },

    /**
     * Add a new data point to the project. Will check if the data point already exists
     * @param dataPoint A data point that should be added to the project
     */
    addNewDataPointToProject(dataPoint: IDataPointModel) {
      // Check if the data point already exists
      const existingDataPoint = self.createdDPList.find(
        (dp) => dp.dataId === dataPoint.dataId
      );
      if (existingDataPoint) {
        // If the data point already exists, update it
        this.updateDataPoint(dataPoint);
        return;
      }
      // Add the data point to the project and order it alphabetically
      self.createdDPList.push(dataPoint);
      self.createdDPList = self.createdDPList.sort((a, b) =>
        a.dataId.localeCompare(b.dataId)
      );
    },

    /**
     * Update a data point in the project
     * @param dataPoint A data point that should be updated
     */
    updateDataPoint(dataPoint: IDataPointModel) {
      const index = self.createdDPList.findIndex(
        (dp) => dp.dataId === dataPoint.dataId
      );
      if (index > -1) {
        self.createdDPList[index] = dataPoint;
      }
    },
  }));

export const ProjectModel = ProjectBaseModel.actions(createDataPointActions)
  .actions(saveSchemasActions)
  .actions(saveFileNameActions)
  .actions(saveMiniSiteConfigActions);

export type IProjectBaseModel = Instance<typeof ProjectBaseModel>;
export type IProjectModel = Instance<typeof ProjectModel>;
export type IVidPartModel = Instance<typeof VidPartModel>;
export type IModModel = Instance<typeof ModModel>;
export type IFlowDiagramModel = Instance<typeof FlowDiagramModel>;

import * as React from "react";
import ResponsiveIframeContainer from "../../components/ResponsiveIframeContainer";
import { info_data } from "../../config";
import { IProjectModel } from "../../stores/project/projectModel";
import { Spin } from "antd";

export function ProjectAnalytics({
  analyticsReportUrl,
}: Partial<IProjectModel>) {
  if (!analyticsReportUrl) {
    return (
      <div>
        <h4>
          Analytics were not set for this project, please
          <a href={`mailto:${info_data.contactMail}`}> contact us</a>
        </h4>
      </div>
    );
  }

  return (
    <ResponsiveIframeContainer
      h={700}
      w={900}
      className={"ProjectAnalytics_iframe-container project-tab-padding"}
    >
      <div className={"loading_iframe"}>
        <Spin />
      </div>
      <iframe
        className={"ProjectAnalytics_iframe"}
        title={"analytics_iframe"}
        src={analyticsReportUrl}
        frameBorder="0"
        style={{
          border: 0,
          // background: 'silver',
          top: 25, // hides google logo
        }}
        allowFullScreen
      />
    </ResponsiveIframeContainer>
  );
  // return (
  //   <div className="responsive-inline-frame">
  //     <div className="responsive-inline-frame-inner">
  //
  //     </div>
  //   </div>
  // )
}

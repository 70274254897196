import { getRoot, Instance, types } from "mobx-state-tree";
import {
  IProjectModel,
  IVidPartModel,
  mainAssetKey,
  ProjectModel,
  sequenceLayerId,
} from "./projectModel";
import _ from "lodash";

import {
  CreateProjectInput,
  Project,
  ProjectVersion,
  UpdateProjectInput,
  ExperimentOptimizationTechnique,
  VidPartInput,
} from "../../API";
import { asyncOpStateType } from "../../types/mst/async-op-state";
import { AsyncOpState } from "../../types/enums/async-op-states";
import { IRootModel, rootStore } from "../Root";
import {
  createPublished,
  createVersion,
  deleteProjectVersionAndPublished,
  getLatestProjectVersion,
  getPublishedProject,
  hasFormData,
  listProjectsBasicInfoByAccount,
  savePublished,
} from "../../utils/projectTools";
import { getJsonDataFromUrl } from "./jsonDataMemo";
import { getAssetLayerPairs } from "./getAssetLayerPairs";
import { projectTitleToAlias } from "../../utils/integration";
import { DynamicThumbnailsModel } from "./DynamicThumbnailModel";
import { AuthSession } from "../../types/awsTypes";
export type ProjectBasicInfo = {
  title: string;
  id: string;
  updatedAt: string;
  description: string;
  thumbS3Url: string;
  createdAt: string;
  projectAccountId: string;
};

export interface SimpleCreateProjectInput
  extends Pick<CreateProjectInput, "title" | "description" | "thumbS3Url"> {
  dataExample: any;
}

export const ProjectsModel = types
  .model("ProjectsStore", {
    projectsIsLoading: true, // Loading of all projects
    projectIdCurrentlyLoading: types.maybeNull(types.string), // Loading of full data for 1 project,
    projectLoadingStatus: asyncOpStateType,
    projects: types.optional(types.array(types.frozen<ProjectBasicInfo>()), []),
    selectedProject: types.maybe(ProjectModel),
    updateOrCreateProjectStatus: asyncOpStateType,
    projectsSubMenuIsOpen: false,
    // selectedProject: types.maybe(ProjectModel)
  })
  .views((self) => ({
    get selectedProjectId() {
      return self.selectedProject?.id;
    },
  }))
  .actions((self) => ({
    changeUpdateOrCreateProjectStatus(newStatus: AsyncOpState) {
      self.updateOrCreateProjectStatus = newStatus;
    },
  }))
  .actions((self) => ({
    addProjects(newProjects: ProjectBasicInfo[]) {
      this.setIsLoading(false);

      // Order the new projects by updatedAt
      newProjects.sort((a, b) => {
        return a.updatedAt > b.updatedAt ? -1 : 1;
      });

      self.projects.replace(newProjects);
    },
    setIsLoading(isLoading: boolean) {
      self.projectsIsLoading = isLoading;
    },
    setLoadingStatus(status: AsyncOpState) {
      self.projectLoadingStatus = status;
    },
    setProjectIdCurrentlyLoading(id: string | null) {
      self.projectIdCurrentlyLoading = id;
    },
    setProjects(projects: ProjectBasicInfo[]) {
      self.projects.replace(projects);
    },
    setSelectedProject(projectModel: IProjectModel | undefined) {
      self.selectedProject = projectModel;
    },
  }))
  .actions((self) => {
    async function createProjectModel(
      publishedVersion: Project,
      draftVersion: ProjectVersion,
      hasFormData: boolean = false
    ) {
      const newProjectModel = {};

      // If the project model has a key which is the same in the published project, update the model
      Object.keys(ProjectModel.properties).forEach((key) => {
        if (_.has(publishedVersion, key)) {
          _.assign(newProjectModel, { [key]: _.get(publishedVersion, key) });
        }
      });

      // Set workspaceVideoparts to the model from draft
      const draftVideoparts = draftVersion?.videoParts || [];
      const workspaceVideoParts = draftVideoparts?.map((vp) => {
        const modsArray =
          vp.modsArr?.map((mod) => ({
            dataStr: mod.dataStr,
            id: mod.id,
            origin: mod.origin,
            name: mod.name,
          })) || null;
        const { __typename, ...rest } = vp;
        const vpWithData: VidPartInput = {
          ...rest,
          modsArr: modsArray,
        };
        return vpWithData;
      });
      if (workspaceVideoParts) {
        const promises = [];
        for (const vp of workspaceVideoParts as IVidPartModel[]) {
          if (!vp.jsonUrl) {
            continue;
          }
          promises.push(
            new Promise(async (resolve, reject) => {
              const jsonData = await getJsonDataFromUrl(vp.jsonUrl);
              vp.jsonData = jsonData;
              vp.initialModsArr = vp.modsArr;
              // Update Asset layer pairs
              const [assetLayerPairs, mainPairs] = getAssetLayerPairs(jsonData);
              assetLayerPairs[mainAssetKey] = mainPairs;
              Object.keys(assetLayerPairs).forEach((key) => {
                if (key.indexOf(sequenceLayerId) === 0) {
                  delete assetLayerPairs[key];
                }
              });
              vp.assetLayerPairs = assetLayerPairs;
              resolve(true);
            })
          );
        }
        await Promise.all(promises);
      }
      // Set workspaceUpdatedAt to the model
      const draftFlowDiagramDraft = draftVersion?.flowDiagram || null;
      const workSpaceUpdatedAt = draftVersion?.updatedAt || "";
      if (workspaceVideoParts) {
        const Project = {
          ...newProjectModel,
          id: publishedVersion.id,
          settings: publishedVersion.settings
            ? JSON.parse(publishedVersion.settings)
            : {},
          projectAccountId: publishedVersion.projectAccountId,
          publishedVideoParts:
            publishedVersion.videoParts?.map((vp) => {
              const { __typename, ...rest } = vp;
              return rest;
            }) || [],
          draftExperiments:
            draftVersion?.experiments?.map((variant) => ({
              id: variant.id,
              variants: variant.variants || [],
              type: variant.type,
            })) || [],
          workSpaceUpdatedAt,
          createdAt: publishedVersion.createdAt || "",
          title: publishedVersion.title,
          workspaceVideoParts: workspaceVideoParts || [],
          aliasId: publishedVersion.aliasId,
          // @ts-ignore
          minisiteConfig: publishedVersion.minisiteConfigs?.length
            ? publishedVersion.minisiteConfigs[0]
            : {},
          dynamicThumbnail: DynamicThumbnailsModel.create({
            projectId: publishedVersion.id,
            id: publishedVersion.dynamicThumbnails?.length
              ? publishedVersion.dynamicThumbnails[0]?.id
              : null,
            thumbnails: publishedVersion.dynamicThumbnails,
          }),
          allowCrmConnect:
            publishedVersion.allowCrmConnect !== undefined &&
            publishedVersion.allowCrmConnect !== null
              ? publishedVersion.allowCrmConnect
              : true,
          allowSdkConnect:
            publishedVersion.allowSdkConnect !== undefined &&
            publishedVersion.allowSdkConnect !== null
              ? publishedVersion.allowSdkConnect
              : true,
          hasFormData: hasFormData,
          flowDiagramDraft: draftFlowDiagramDraft,
        };
        return ProjectModel.create(Project);
      }
    }
    return {
      async loadProject(id: string) {
        if (self.selectedProject?.id === id) {
          return;
        }
        self.setLoadingStatus(AsyncOpState.Saving);
        self.setProjectIdCurrentlyLoading(id);
        try {
          // Get the latest published and draft versions
          const loadingPromises = [
            getPublishedProject(id),
            getLatestProjectVersion(id),
            hasFormData(id),
          ];
          const result = await Promise.all(loadingPromises);
          const publishedVersion = result[0] as Project;
          let draftVersion = result[1] as ProjectVersion | null;
          const _hasFormData = result[2] as boolean;

          if (!draftVersion) {
            if (!publishedVersion) throw new Error("Invalid Project");
            // If there is no draft version, create one from the published version
            draftVersion = (await createVersion(
              publishedVersion
            )) as ProjectVersion;
          }

          // Create the model
          const selectedProjectModel = await createProjectModel(
            publishedVersion,
            draftVersion,
            _hasFormData
          );
          if (selectedProjectModel) {
            self.setSelectedProject(selectedProjectModel);
            self.setLoadingStatus(AsyncOpState.Success);
            self.setProjectIdCurrentlyLoading(null);
            getRoot<IRootModel>(self).experimentStore.replaceExperiments(
              publishedVersion.experiments || []
            );
            getRoot<IRootModel>(self).experimentStore.replaceExperiments(
              draftVersion.experiments || [],
              true
            );
            getRoot<IRootModel>(
              self
            ).experimentStore.setExperimentOptimizationTechnique(
              publishedVersion.experimentOptimizationTechnique ||
                ExperimentOptimizationTechnique.DISABLED
            );
          } else {
            self.setLoadingStatus(AsyncOpState.Error);
          }
        } catch (e) {
          self.setLoadingStatus(AsyncOpState.Error);
          console.error(e);
        }
      },
      loadProjectsSuccess() {
        self.setIsLoading(false);
        // Store all the project in the local storage
        if (self.projects.length > 0) {
          localStorage.setItem("projects", JSON.stringify(self.projects));
        }
      },

      removeProjectsFromLocalStorage() {
        localStorage.removeItem("projects");
      },
    };
  })
  .actions((self) => ({
    loadProjectsFromLocalStorage() {
      // Check if we have the projects in the local storage
      const localProjects = localStorage.getItem("projects");
      if (localProjects) {
        const _localProjects = JSON.parse(localProjects);
        if (localProjects.length > 0) {
          self.setProjects(_localProjects);
          self.setIsLoading(false);
        }
      }
    },
    async loadProjects() {
      // Then we get all the updated projects from the server
      let accounts = rootStore.accountStore.allGroupIds as string[];
      if (accounts.length === 0 || !accounts[0]) {
        const fullAuth = rootStore.accountStore.AuthSession as AuthSession;
        accounts = fullAuth.tokens?.idToken?.payload[
          "cognito:groups"
        ] as string[];
      }
      const promises: Promise<ProjectBasicInfo[] | undefined>[] = [];
      accounts.map((account) =>
        promises.push(listProjectsBasicInfoByAccount(account))
      );
      const projects = await Promise.all(promises);
      const allProjects = projects.flat().filter((p) => !!p?.id);
      self.addProjects(allProjects as ProjectBasicInfo[]);
      self.loadProjectsSuccess();
    },

    async setSelectedProjectById(id?: string) {
      // Only load project if Id is valid
      // If there is a project loading, continue only if the new id is not equal to the one currently selected
      if (id) {
        if (self.projectLoadingStatus === AsyncOpState.Error) {
          if (self.projectIdCurrentlyLoading !== id) {
            //If the id with the error is different than the one we want to load, load it
            await self.loadProject(id);
          }
        } else if (
          !self.projectIdCurrentlyLoading ||
          self.projectIdCurrentlyLoading !== id
        ) {
          await self.loadProject(id);
        }
      }
    },
    async refreshProject(id?: string) {
      // Only load project if Id is valid
      // If there is a project loading, continue only if the new id is not equal to the one currently selected
      if (id) {
        if (
          !self.projectIdCurrentlyLoading ||
          self.projectIdCurrentlyLoading !== id
        ) {
          self.setSelectedProject(undefined); // Clear the project
          await self.loadProject(id);
        }
      }
    },
    setProjectsSubMenuIsOpen(isOpen: boolean) {
      self.projectsSubMenuIsOpen = isOpen;
    },
    async createProjectNotAdmin(cpiSimple: SimpleCreateProjectInput) {
      const groupId = getRoot<IRootModel>(self).accountStore.cognitoGroupId;
      if (!groupId) {
        throw new Error("missing group ID");
      }

      const { description, title, thumbS3Url } = cpiSimple;
      const defaultSchema = JSON.stringify({
        $schema: "http://json-schema.org/draft-07/schema",
        $id: "http://example.com/example.json",
        type: "object",
        title: "",
        description: "",
        default: {},
        examples: [{}],
        required: [],
        properties: {},
        additionalProperties: true,
      });
      const aliasId = projectTitleToAlias(title);
      const cpi: CreateProjectInput = {
        projectAccountId: groupId,
        description,
        title,
        settingsJsonSchemaStr: defaultSchema,
        stateJsonSchemaStr: defaultSchema,
        allowCreateDataPoint: true,
        analyticsEnabled: true,
        allowDataConnect: true,
        allowSdkConnect: true,
        allowCrmConnect: true,
        allowSendFormData: true,
        thumbS3Url,
        aliasId,
      };

      return await this.createProject(cpi);
    },
    async createProject(cpi: CreateProjectInput) {
      self.changeUpdateOrCreateProjectStatus(AsyncOpState.Saving);
      let newProjectInfo: Project | null = null;
      try {
        newProjectInfo = await createPublished(cpi);
        self.changeUpdateOrCreateProjectStatus(AsyncOpState.Success);
      } catch (e) {
        self.changeUpdateOrCreateProjectStatus(AsyncOpState.Error);
        console.error("err", e);
      }
      setTimeout(() => {
        self.changeUpdateOrCreateProjectStatus(AsyncOpState.Changed);
      }, 2000);
      this.loadProjects();
      return newProjectInfo;
    },
    async saveProject(upi: UpdateProjectInput) {
      try {
        // @ts-ignore
        delete upi.videoPartsStr;
      } catch (e) {
        console.error("err", e);
      }
      self.changeUpdateOrCreateProjectStatus(AsyncOpState.Saving);

      try {
        await savePublished(upi);
        this.loadProjects();
        self.changeUpdateOrCreateProjectStatus(AsyncOpState.Success);
      } catch (e) {
        self.changeUpdateOrCreateProjectStatus(AsyncOpState.Error);
        console.error("err", e);
      }
      setTimeout(() => {
        self.changeUpdateOrCreateProjectStatus(AsyncOpState.Changed);
      }, 2000);
    },
    async deleteProject(id: string) {
      // admin only!
      try {
        const projects = self.projects;
        const newProjects = projects.filter((p) => p.id !== id);
        localStorage.setItem("projects", JSON.stringify(newProjects));
        await deleteProjectVersionAndPublished(id);

        this.loadProjects();
      } catch (e) {
        console.error("err delete Project", e);
      }
    },
  }))
  .actions(() => ({
    afterCreate() {
      // self.loadProjects()
    },
  }));

export type IProjectsModel = Instance<typeof ProjectsModel>;

import { BlingsBtn } from "../../components/antd-extensions/blings-btn.component";
import { AsyncOpState } from "../../types/enums/async-op-states";

type SaveEditVideoComponentProps = {
  disabled: boolean;
  onclick: () => void;
  saveModsStatus: AsyncOpState;
};

const SaveEditVideoComponent = ({
  disabled,
  onclick,
  saveModsStatus,
}: SaveEditVideoComponentProps) => {
  const saveButtonTexts = {
    [AsyncOpState.Success]: "Update",
    [AsyncOpState.Untouched]: "Update",
    [AsyncOpState.Changed]: "Update",
  };
  return (
    <BlingsBtn
      opState={saveModsStatus}
      htmlType={"submit"}
      isSecondary={true}
      btnTexts={saveButtonTexts}
      onClick={onclick}
      disabled={disabled}
    />
  );
};

export default SaveEditVideoComponent;

import { Button, Spin } from "antd";
import { ButtonProps, ButtonType } from "antd/lib/button";
import React from "react";
import { AsyncOpState } from "../../types/enums/async-op-states";
import "./blings-btn.component.scss";
export type BlingsBtnProps = {
  opState: AsyncOpState;
  btnTexts?: IBtnTexts;
  disabled?: boolean;
  isSecondary?: boolean;
} & Pick<ButtonProps, "htmlType"> &
  Pick<ButtonProps, "onClick"> &
  Pick<ButtonProps, "shape" | "ghost" | "className">;

export const BlingsBtn = ({
  opState,
  htmlType,
  btnTexts = {},
  onClick,
  isSecondary,
  disabled,
  className,
}: BlingsBtnProps) => {
  const isDisabledState = isDisabled(opState) || disabled;
  const btnType = getBtnType(opState);
  const className1 =
    isSecondary ?
    "opaque-button " : "" +
      className +
      " " +
      getClassName(opState) +
      " " +
      (isDisabledState ? "disabled" : "");
  // console.log("TCL: BlingsBtn -> className", className)
  const btnContent = getBtnContent(opState, btnTexts);
  return (
    <Button
      type={btnType}
      htmlType={htmlType}
      className={className1}
      disabled={isDisabledState}
      onClick={onClick}
    >
      {btnContent}
    </Button>
  );
};

const getClassName = (opState: AsyncOpState) => {
  return (
    "BlingsBtn " +
    (opState === AsyncOpState.Success ? "blings-btn-success" : undefined)
  );
};

const isDisabled = (opState: AsyncOpState) => {
  const disableBtnState = [
    AsyncOpState.Untouched,
    AsyncOpState.Saving,
    AsyncOpState.Success,
    AsyncOpState.Error,
  ];

  return disableBtnState.includes(opState);
};

const getBtnType = (
  opState: AsyncOpState,
  btnTypeFromProp?: ButtonType
): ButtonType | undefined => {
  switch (opState) {
    case AsyncOpState.Saving:
      return "dashed";
    case AsyncOpState.Success:
      return undefined;
    default:
      return btnTypeFromProp || "primary";
  }
};

const getBtnContent = (opState: AsyncOpState, btnTexts: IBtnTexts) => {
  return opState === AsyncOpState.Saving ? (
    <Spin size="small" style={{ height: "17.6px" }} />
  ) : (
    getBtnTxt(opState, btnTexts)
  );
};

export type IBtnTexts = {
  [key in AsyncOpState]?: string;
};

const getBtnTxt = (opState: AsyncOpState, btnTexts: IBtnTexts) => {
  switch (opState) {
    case AsyncOpState.Error:
      return btnTexts[AsyncOpState.Error] || "Error";
    case AsyncOpState.Saving:
      return btnTexts[AsyncOpState.Saving] || "Saving";
    case AsyncOpState.Success:
      return btnTexts[AsyncOpState.Success] || "Saved";
    case AsyncOpState.Untouched:
      return btnTexts[AsyncOpState.Untouched] || "Save";
    case AsyncOpState.Changed:
      return btnTexts[AsyncOpState.Changed] || "Save";

    default:
      return "Save";
  }
};
